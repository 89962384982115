// import "@babel/polyfill";

import $ from 'jquery';
window.$ = window.jQuery = $;

import 'slick-carousel'
import "slick-carousel/slick/slick.css";

import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';

import 'lightgallery';
import 'lightgallery/dist/css/lightgallery.min.css';

import './tailwind.css'

global.Cookies = require('js-cookie');

window.Lightbox = (function(){
  return{
    init: function(){
    	console.log('initalizing Lightbox');

    	$('.lightbox-js-wrapper').each(function(){
			if ( !$(this).hasClass('lightbox-initialized')){
				$(this).lightGallery({
					download: false,
					// thumbnail: true,
				    selector: '.lightbox-js'
				});
				$(this).addClass('lightbox-initialized')
			}
		})
    }
  }
}());


// var input = document.querySelector(".phone-ext-js");
$('.phone-ext-js').each(function(){
	let input = $(this)[0];
	let that = $(this);

	// data-name = input name
	// data-default = default extension

	let name = $(this).data('name')?$(this).data('name'):($(this).attr('name')+"[phone_ext]"); //set input name
	$(this).after('<input type="hidden" class="phone-ext-value" name="'+name+'">');
	let $hiddenInput = $(this).next();

	let initialCountry = 'hk'; //set default
	let defaultcode = $(this).data('default');
	if ( defaultcode ){
		window.intlTelInputGlobals.getCountryData().forEach(function(country){
			if ( country.dialCode==defaultcode){
				initialCountry = country.iso2;
			}
		})
	}

	var iti = intlTelInput(input, {
		autoHideDialCode: false,
		separateDialCode: true,
		initialCountry: initialCountry,
		preferredCountries: ["hk","tw"],
	    // any initialisation options go here
	});
	input.addEventListener("countrychange", function() {
		$hiddenInput.val( iti.getSelectedCountryData().dialCode );
		// console.log(iti.getSelectedCountryData().dialCode);
	});
	$hiddenInput.val( iti.getSelectedCountryData().dialCode );
})
